<template>
  <div>
    <b-modal
      modal
      title="ADD FILE"
      v-model="modalAddFile"
      size="xmd"
      modal-class="modal-primary"
      :no-close-on-backdrop="true"
      @hidden="closeModal"
      title-tag="h3"
    >
      <ValidationObserver ref="form">
        <b-row cols="1">
          <b-col class="mt-2">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-input-group prepend="Zip Code">
                <b-form-input
                  type="number"
                  :state="errors[0] ? false : null"
                  v-model="zipCode"
                ></b-form-input>
              </b-input-group>
            </ValidationProvider>
          </b-col>
          <b-col class="mt-2 mb-2">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-file
                v-model="form.file"
                @change="onImageChange"
                accept="application/pdf"
                :state="errors[0] ? false : null"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <b-row>
          <b-col align-self="start">
            <b-button variant="success" @click="savePoBox">
              <feather-icon icon="UploadIcon" />
              UPLOAD</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FilePOBoxService from "@/views/commons/components/file-po-box/services/file-po-box.service";

export default {
  data() {
    return {
      zipCode: "",
      form: {
        file: [],
        namedoc: "",
        size: "",
        ext: "",
      },
      modalAddFile: false,
    };
  },
  created() {
    this.modalAddFile = true;
  },
  methods: {
    onImageChange(e) {
      let fileReader = new FileReader();

      fileReader.readAsDataURL(e.target.files[0]);

      fileReader.onload = (e) => {
        this.form.file = e.target.result;
      };
      const file = e.target.files[0].name;
      const size = e.target.files[0].size;
      this.form.namedoc = file;
      this.form.size = size;
    },
    async savePoBox() {
      let success = await this.$refs.form.validate();
      if (!success) {
      } else {
        try {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            this.addPreloader();
            const params = {
              zip_code: this.zipCode,
              user_id: this.currentUser.user_id,
              pdf: this.form,
            };
            const response = await FilePOBoxService.savePoBox(params);
            if (response.status == 200) {
              this.$emit("reload");
              this.removePreloader();
              this.showSuccessSwal();
              this.closeModal();
            }
          }
        } catch (e) {
          this.removePreloader();
          console.log(e);
        }
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style></style>
