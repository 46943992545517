<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row align-h="end">
          <b-col>
            <b-button
              variant="success"
              v-if="moduleId == 9"
              @click="openModalAddFile"
            >
              <feather-icon icon="PlusIcon" />
              ADD</b-button
            >
          </b-col>
        </b-row>
      </template>
    </header-slot>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-if="moduleId !== 4"
        :to="{ name: 'correspondence-po-box-identify' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >INDETIFY</b-nav-item
      >
      <b-nav-item
        v-if="moduleId !== 4"
        :to="{ name: 'correspondence-po-box-verified' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >VERIFIED</b-nav-item
      >
      <b-nav-item
        v-if="moduleId == 4"
        :to="{ name: 'administration-po-box-identify' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >INDETIFY</b-nav-item
      >
      <b-nav-item
        v-if="moduleId == 4"
        :to="{ name: 'administration-po-box-verified' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >VERIFIED</b-nav-item
      >
      <b-nav-item
        v-if="moduleId == 4"
        :to="{ name: 'administration-po-box-deleted' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >DELETED</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="key" />
    </b-card>

    <modal-add-file
      v-if="modalAddFile"
      @reload="key++"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import ModalAddFile from "@/views/commons/components/file-po-box/components/modals/AddFile.vue";

export default {
  components: {
    ModalAddFile,
  },
  data() {
    return {
      modalAddFile: false,
      key: 0,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    openModalAddFile() {
      this.modalAddFile = true;
    },
    closeModal() {
      this.modalAddFile = false;
    },
  },
};
</script>

<style></style>
